<template>
  <div>
    <v-app-bar elevation="4">
      <v-img
        :src="require('@/assets/images/logos/logo-small.png')"
        max-width="130px"
        alt="logo"
        contain
        class="me-3"
      ></v-img>
    </v-app-bar>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card">
          <!-- logo -->
          <v-card-title style="width: 100%; display: block; text-align: center" class="align-center justify-center">
            <router-link style="" to="/" class="align-center">
              <h2 class="text-2xl font-weight-semibold">SIDIKBANG TEGAL</h2>
              <h5>
                SISTEM DIGITALISASI <br />Pendidikan, Pelatihan dan Pengembangan<br />RSUD dr SOESELO KABUPATEN TEGAL
              </h5>
            </router-link>
          </v-card-title>

          <v-card-text>
            <v-form>
              <v-text-field
                v-model="email"
                outlined
                label="Email"
                dense
                placeholder="xxx / xxx@xxx.com"
                hide-details
                class="mb-2"
              ></v-text-field>
              <VueRecaptcha
                :sitekey="siteKey"
                :load-recaptcha-script="true"
                @verify="handleSuccess"
                @error="handleError"
              >
              </VueRecaptcha>
              <v-btn block color="primary" class="mt-2" @click="resend()" :loading="loading"> Kirim Ulang </v-btn>
            </v-form>
          </v-card-text>
          <v-card-text class="align-center justify-center flex-wrap mt-2 text-center">
            <span> Sudah verifikasi email? </span>
            <router-link :to="{ name: 'login' }"> Login </router-link>
            <br />
            <span class="me-2"> Belum punya akun? </span>
            <router-link :to="{ name: 'register' }"> Register </router-link>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { VueRecaptcha } from 'vue-recaptcha'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'

export default {
  components: {
    VueRecaptcha,
  },
  setup() {
    const siteKey = process.env.VUE_APP_CAPTCHA_KEY

    const kode = ref('')
    const email = ref('')

    return {
      siteKey,
      email,
      kode,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data: () => ({
    loading: false,
    recaptcha: '',
  }),
  methods: {
    handleError() {},
    handleSuccess(response) {
      this.recaptcha = response
    },
    resend() {
      this.loading = true
      const fmData = new FormData()
      fmData.append('email', this.email)
      fmData.append('recaptcha', this.recaptcha)
      axiosPostAuth('Authentication/resend', fmData)
        .then(response => {
          if (response.code == 200) {
            alert(response.message)
            this.$router.push({ name: 'Verifikasi' })
          } else {
            alert(response.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
